<template>
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<a class="layout-topbar-mobile-button p-ripple" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right">
			<div class="layout-topbar-actions-left">
				Váci egyházmegye - Kérdőív rendszere
			</div>

			<div class="layout-topbar-actions-right">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		props: {
		},
		methods: {
		},
		computed: {
		}
	}
</script>
