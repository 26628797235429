import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'mainpage',
        exact: true,
        component: () => import('./pages/Mainpage.vue'),
        meta: {
            breadcrumb: [{ label: 'Mainpage' }],
        },
    },
    {
        path: '/view',
        name: 'viewpage',
        exact: true,
        component: () => import('./pages/Viewpage.vue'),
        meta: {
            breadcrumb: [{ label: 'Viewpage' }],
        },
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;